import { Component } from '@angular/core';
import { AppService } from '../../app.service';
import { HeaderService } from '../header/header.service';
import { IProfileLinks } from '../../../models/profilelink.model';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})

export class FooterComponent {

  footerLinks = { corporateHome: '', termsAndConditions: '', privacyAndPolicy: '', contactUs: '' };
  errorMessage = '';
  lastHeight = 0;
  userProfileLinks: IProfileLinks[];
  currentYear = new Date().getFullYear();

  constructor(
    private headerService: HeaderService,
    private appService: AppService
    ) {
      this.appService.getConfigStatus().subscribe(
        _configStatus => {
          if (_configStatus) {
            this.headerService.getAllUserProfileLinks(this.appService.getGlobalConfig().apiUrl).subscribe(
              _userProfileLinks => {
                this.userProfileLinks = _userProfileLinks;
                if(_userProfileLinks){
                  this.setUserProfile(_userProfileLinks);
                }
              },
              error => this.errorMessage = error as any
            );
          }
        }
      );
  }

  setUserProfile(_userProfileLinks){
    _userProfileLinks.forEach(item => {
      if (item.code === 'ISO_HOME') {
        this.footerLinks.corporateHome = item.url;
      }
      if (item.code === 'TERMS') {
        this.footerLinks.termsAndConditions = item.url;
      }
      if (item.code === 'POLICY') {
        this.footerLinks.privacyAndPolicy = item.url;
      }
      if (item.code === 'CONTACT') {
        this.footerLinks.contactUs = item.url;
      }
    });
  }

}
