import { Component, ElementRef } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { EntitlementService } from './shared/service/entitlement.service';
import { AppService } from './app.service';
import { IConfig } from 'src/models/config';
import { NgxSpinnerService } from "ngx-spinner";
import { ProfileSelectionService } from './shared/service/profile-selection.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

declare function loadCustomerSelectionJS(): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'ISO Experience Profiler';
  
  errorMessage: any;
  customerSupportUrl: string;
  appConfig: IConfig;
  deepLinkURL: string = '';

  isAuthenticated = false;
  isEligible: boolean = false;
  isLoading: boolean = true;
  token: string;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private entitlementService: EntitlementService,
    private cookieService: CookieService,
    private profileSelectionService: ProfileSelectionService,
    private appService: AppService,
    private elementRef: ElementRef
  ) {
    this.router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  setAuthConfig() {
    this.appService.getConfigStatus().subscribe(
      configStatus => {
        if (configStatus) {
          this.appConfig = this.appService.getGlobalConfig();
          this.appConfig.redirectUrl = this.getRedirectUrl(this.appConfig.redirectUrl);
          this.customerSupportUrl = this.appConfig.veriskCustomerSupport;
          this.setAuthStatus();
        }
      },
      error => (this.errorMessage = error as any)
    );
  }


  getRedirectUrl(redirectUrl: string): string {
    const regexUrl = new RegExp(redirectUrl);
    let newUrl: string;

    if (regexUrl.test(window.location.href)) {
      newUrl = regexUrl.exec(window.location.href)[0].replace('.iso.com', '.verisk.com');
    } else {
      newUrl = window.location.href.replace('.iso.com', '.verisk.com');
    }

    const url = new URL(newUrl);
    if (url.search) {
      url.searchParams.append('domainMigrated', 'true');
    } else {
      url.search = '?domainMigrated=true';
    }

    return url.toString();
  }
  setAuthStatus() {
    this.entitlementService.getLoginStatus().subscribe(
      status => {
        this.isAuthenticated = status;
        if (this.isAuthenticated && this.appConfig.loadCustomerSelection && this.appConfig.loadCustomerSelection != "") {
          this.setCoookieToken();

          if (!document.querySelector('script[src="' + this.appConfig.loadCustomerSelection + '"]')) {
            let id = "customer_selection_switch";
            let element = document.getElementById(id);
            if (!(element)) {
              let s = document.createElement("script");
              s.type = "text/javascript";
              s.src = this.appConfig.loadCustomerSelection;
              this.elementRef.nativeElement.appendChild(s);
              setTimeout(() => {
                this.profileSelectionService.setProductContextCookie();
                loadCustomerSelectionJS();
              }, 5000);
            }
          }
        }
      });
  }

  checkRouterEvent(routerEvent: Event): void {
    this.errorMessage = "";
    if (routerEvent instanceof NavigationEnd) {
      this.setCoookieToken();
      if (this.token && this.token != '') {
        this.entitlementService.setLoginStatus(true);
        this.appService.getConfigStatus().subscribe(_configStatus => {
          if (_configStatus) {
            this.profileSelectionService.setProductContextCookie();
            this.profileSelectionService.hasCompanyAccess().pipe(
              switchMap(hasAccess => {
                if (hasAccess === false) {
                  this.createDeepLinkUrl();
                  this.router.navigate(['/unauthorized']);
                  return of(false); // If the user doesn't have company access, return an observable that completes immediately
                }
                return this.profileSelectionService.includeIdealUX();
              }),
              switchMap((hasCompanyAccess) => {
                if (hasCompanyAccess === false) {
                  this.router.navigate(['/unauthorized']);
                  return of(false);
                }
              })
            )
              .subscribe(
                result => {
                  this.createDeepLinkUrl();
                },
                error => {
                  this.errorMessage = error;
                }
              );
            this.setAuthConfig();
          }
        });
      }
    }
  }

  createDeepLinkUrl() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    // Remove the redirectedFromMenu query parameter
    params.delete('redirectedFromMenu');

    // Update the URL with the modified query parameters
    url.search = params.toString();
    this.deepLinkURL = url.toString();
  }

  setCoookieToken() {
    let userId = localStorage.getItem("userid");
    let oktaStorage = localStorage.getItem("okta-token-storage");

    let token = JSON.parse(oktaStorage);

    if (token) {
      token = JSON.parse(oktaStorage).accessToken.accessToken;
      this.cookieService.set("access_token", token, null, '/', '', true);
    }

    if (userId) {
      document.cookie = `userid=${userId}; path=/; Secure; SameSite=Strict`;
    }
    
    this.token = token;
  }
}
