import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { CookieService } from 'ngx-cookie-service';
import { HeaderService } from './components/header/header.service';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UserProfileFilterPipe } from './shared/pipes/userprofilefilter.pipe';
import { FileNamePipe } from './shared/pipes/filename.pipe';
import { RouterModule } from '@angular/router';
import { ProfileSelectionInterceptor } from './shared/interceptor/profile-selection.interceptor';
import { VrskAuthOktaModule, VRSK_AUTH_OKTA_CONFIG } from '@uw-verisk/auth-okta';
import { AddHeaderInterceptor } from './shared/interceptor/add-header.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    UserProfileFilterPipe,
    HomeComponent,
    UnauthorizedComponent,
    FileNamePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    VrskAuthOktaModule,
    RouterModule.forRoot([])
  ],
  providers: [
    CookieService,
    HeaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProfileSelectionInterceptor,
      multi: true,
      deps: [CookieService]
    },
    {
      provide: VRSK_AUTH_OKTA_CONFIG,
      useValue: '../assets/jsons/config.json'
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
