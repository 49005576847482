import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { VrskGatewayLoginGuard, VrskOktaLoginFlowComponent, VrskAuthOktaLogoutComponent } from '@uw-verisk/auth-okta';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '',
    canActivate: [VrskGatewayLoginGuard],
    component: HomeComponent 
  },
  {
    path: 'auth',
    children: [
      {
        path: 'callback',
        component: OktaCallbackComponent
      },
      {
        path: 'oktaLoginFlow',
        component: VrskOktaLoginFlowComponent
      },
      {
        path: 'logout',
        component: VrskAuthOktaLogoutComponent
      },
      { path: 'logged-out', pathMatch: 'full', redirectTo: '/' }
    ]
  },
  { 
    path: 'unauthorized', 
    component: UnauthorizedComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
