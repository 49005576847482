import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AdobeAnalyticsService {
  digitalData: any;
  constructor(private cookieService: CookieService) {
    this.digitalData = {
      page:
      {
        pageInfo: {
          pageID: '',
          pageURL:window.location.origin.concat(window.location.pathname),
          pagePath: window.location.pathname,
          productname: 'Experience Profiler'
        },
        category: {
          primaryCategory: 'home',
          subCategory1: ''
        },
        user: {
          userName: localStorage.getItem('userid'),
          companyID: localStorage.getItem('cstid'),
          companyName: localStorage.getItem('customername')
        }
      }
    };
  }

  public updateDigitalData(data: any) {
    this.digitalData = {
      page:
      {
        pageInfo: {
         ...this.digitalData.page.pageInfo,...data.page.pageInfo
        },
        category: {
          ...this.digitalData.page.category,...data.page.category
        },
        filters:{
         ... this.digitalData.page.filters
        },
        user:
        {
          ...this.digitalData.page.user
        }
      }
    } 
    window['digitalData'] = this.digitalData;
  }

  public emit(data?: any,status?:string) {
    if (data) {
      this.updateDigitalData(data);
    }
    var eventName:string= status==="start"?'event-view-start':'event-view-end';
    const evt = new CustomEvent(eventName);
    document.body.dispatchEvent(evt);
  }

}
